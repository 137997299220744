<template>
  <auth-layout>
    <h5 class="text-center mt-2">Yeni Şifre Oluşturun</h5>
    <validation-observer ref="loginForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="changePassword">
        <app-input id="password" v-model="password" type="password" name="Şifre" label="Şifre:" placeholder="············" rules="required|min:6" />
        <app-input v-model="passwordCheck" type="passwordCheck" name="Şifre Doğrulama" label="Şifre Doğrulama:" placeholder="············" rules="required|confirmed:password" />
        <b-button variant="primary" type="submit" block :disabled="invalid"> Oluştur </b-button>
      </b-form>
    </validation-observer>
    <b-card-text class="text-center mt-2">
      <b-link @click="$store.dispatch('logout')">
        <span>Çıkış Yap</span>
      </b-link>
    </b-card-text>
  </auth-layout>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AuthLayout from "../AuthLayout.vue";

export default {
  components: {
    ValidationObserver,
    AuthLayout,
  },
  data() {
    return {
      password: null,
      passwordCheck: null,
    };
  },
  methods: {
    changePassword() {
      this.$store.dispatch("changeTempPassword", {
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
